.features-page {
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #1e293b;
  font-weight: 400;
  padding: 2rem;
  //   background: url('https://seatylive.blob.core.windows.net/images/FeatureIcons/Website%20Background.svg') repeat center
  //     center;
  background-size: contain;
  background-color: #54c5e8;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 100px 0;

  .features-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
  }

  .feature__info {
    position: relative;
    margin: 0 auto;
    max-width: 1400px;
    text-align: center;

    .feature__title {
      font-size: 4rem;
      font-weight: 800;
      margin-bottom: 0.25rem;
    }

    .feature__subtitle {
      font-size: 2rem;
      font-weight: 400;
    }

    .feature__intro {
      font-size: 1.4rem;
      font-weight: 400;
      max-width: 1000px;
      margin: 0 auto;
      padding: 1rem 3rem;
      border-radius: 4rem;
      margin-bottom: 2rem;
      color: #333;
      position: relative;
    }
  }

  .feature {
    position: relative;
    display: flex;
    margin: 0 auto;
    max-width: 1200px;
    margin-bottom: 150px;

    .feature__left {
      text-align: right;
      flex: 1 1 45%;
      padding: 1.5rem;
    }

    .feature__mascot {
      width: 100%;
    }

    .feature__right {
      flex: 1 1 55%;
      padding: 1.5rem;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .feature__item {
        background: #ffffff;
        border-radius: 4rem;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
        max-width: 800px;
        margin: 0 auto;
        width: 100%;
        position: relative;
        padding: 20px 40px;
        padding-left: 130px;

        .feature__item__image {
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
          width: 100px;
          height: 100px;
          border-radius: 4rem;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 85px;
            max-height: 85px;
            object-fit: contain;
          }
        }

        .feature__item_content {
          flex: 1;

          .feature__item_title {
            font-size: 1.4rem;
            font-weight: 800;
            line-height: 1.4;
          }

          .feature__item_description {
          }
        }
      }
    }
  }
}
