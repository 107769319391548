.key-banner {
  display: flex;
  flex-direction: row;
  margin: 0;
  text-align: center;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;
  padding: 6px 20px;
  padding-bottom: 16px;
  position: relative;

  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: center; /* Align items vertically in the center */

  @media (max-width: 767px) {
    padding: 5px 10px;
    padding-bottom: 16px;
  }

  .service-fee-message {
    position: absolute;
    bottom: 1px;
    font-size: 10px;
    line-height: 10px;
    color: gray;
    font-weight: 400;
    white-space: nowrap;
    padding: 0 15px;
  }

  // border-top: 2px solid #ebebeb;
  // border-bottom: 2px solid #ebebeb;
  .key-item,
  .key-label {
    font-family: niveau-grotesk, sans-serif;
    max-width: 100%;
    position: relative;
    margin: 0 2px;
    padding: 5px 8px;
    padding-left: 36px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.2s ease-out all;
    text-align: left;
    background: white;
    white-space: nowrap;
    display: block;
    flex-shrink: 0;
    border-bottom: 2px solid #fff;

    .key-item-label {
      font-size: 10px;
      line-height: 10px;
      color: gray;
      font-weight: 400;
      transition: 0.2s ease-out all;
      white-space: nowrap;
    }

    .key-item-text {
      transition: 0.2s ease-out all;
    }

    .key-item-color {
      position: absolute;
      border-radius: 100%;
      left: 7.5px;
      top: 50%;
      transform: translateY(-50%);
      width: 22px;
      height: 22px;

      &:after {
        transition: 0.2s ease-out all;
        content: '';
        position: absolute;
        top: -1.8px;
        left: -1.8px;
        width: 25.6px;
        height: 25.6px;
        border: 2px solid rgba(0, 0, 0, 0);
        border-radius: 100%;
      }

      // &:before {
      //   left: 50%;
      //   top: 50%;
      //   transform: translate(-50%, -50%);
      //   width: 20px;
      //   height: 20px;
      //   border-color: rgba(255, 255, 255, 0.6);
      // }

      img {
        height: 14px;
        width: 14px;
        left: 4.5px;
        top: 4px;
        position: absolute;
      }

      .key-item-wheelchair {
        height: 15px;
        width: 15px;
        top: 3.5px;
        left: 4.5px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 48px;
      bottom: -1px;
      height: 2px;
      background: hsla(243, 94%, 67%, 1);
      border-radius: 1px;
      opacity: 0;
      transition: 0.2s ease-out all;
    }

    border: 2px solid #ebebeb;
    border-radius: 6px;

    &.key-item__selected {
      border: 2px solid hsla(243, 94%, 67%, 1);
      color: rgb(52, 47, 160);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

      &:after {
        // opacity: 1;
      }

      .key-item-label {
        color: hsla(243, 94%, 67%, 1);
      }

      .key-item-text {
        //   text-decoration: underline;
      }

      .key-item-color {
        &:after {
          // border-color: rgba(255, 255, 255, 0.5);
        }
      }
    }

    // &:hover {
    //   //color: hsla(243, 94%, 67%, 0.7);
    //   background: hsla(243, 94%, 67%, 1);
    //   border-color: darken(hsla(243, 94%, 67%, 1), 10%);

    //   .key-item-label {
    //     //color: hsla(243, 94%, 67%, 0.7);
    //     color: white;
    //   }

    //   .key-item-text {
    //     color: white;
    //   }

    //   .key-item-color {
    //     &:after {
    //       border-color: #fff;
    //     }
    //   }
    // }
  }

  .key-label {
    font-weight: 700;
    padding-left: 0px;
    cursor: default;

    &:hover {
      color: #102b35;
    }
  }
}

.seating-plan-label-input {
  width: 100%;
  height: 100%;
}

.animating-viewer {
  .svg-pan-transformer {
    transition: all 0.3s ease-out;
  }
}

.seating-plan-svg-content {
  background-color: white;
  position: relative;
  overflow: hidden;

  .placeholder {
    fill: #e0f0ff;
    cursor: pointer;
  }

  .pillar {
    cursor: pointer;
  }

  .seating-plan-label {
    display: block;
    user-select: none;
    font-family: niveau-grotesk, sans-serif !important;
    text-align: center;
    text-transform: uppercase;
    pointer-events: none;
    fill: #000e15;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.6px;
    background: pink;
    width: 32px;
    height: 32px;
  }

  .seatingplan-object {
    &.selecting {
      opacity: 0.5;
    }
  }

  &.is-admin {
    .seat.disabled.locked {
      fill: #eaeaea;
    }
  }

  .seat {
    user-select: none;

    &.dragging {
      opacity: 0.5;
    }

    circle {
      transition: fill 0.5s;
    }

    text {
      opacity: 1;
    }

    &.middle-seat {
      text {
        opacity: 0;
      }

      &:hover {
        text {
          opacity: 1;
        }
      }
    }

    &.selected {
      text {
        opacity: 1;
      }
    }

    image {
      transition: opacity 0.5s;
    }

    text {
      user-select: none;
      font-family: roboto-mono, monospace !important;
      font-weight: 700;
      text-align: center;
      fill: #e4edf5;
      transition: opacity 0.5s;

      &.hidden {
        opacity: 0;
      }
    }

    &.disabled {
      cursor: default;

      text {
        opacity: 0;
      }

      image {
        opacity: 0;
      }
    }

    &.selecting {
      .vector {
        background-color: rgb(35, 186, 255) !important;
        color: white;
      }

      &.disabled,
      &.locked {
        .vector {
          background-color: #fff !important;
          color: white;
        }
      }
    }
  }

  div[role='toolbar'] {
    display: none !important;
  }

  div[role='navigation'] {
    display: none;
  }

  .plan-toolbar {
    position: absolute;
    z-index: 1;
    top: 55px;
    right: 15px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    .group {
      background: #fff;
      border-bottom: 0;
      height: 120px;
      position: relative;

      label {
        color: #102b35;
        font-family: niveau-grotesk, sans-serif !important;
        font-weight: 400;
        cursor: pointer;
        padding: 8px 0;
        z-index: 1;
        background: transparent;
        padding-bottom: 0;
        margin: 0;
      }

      button {
        display: block;
        background: transparent;
        border: 0;
        position: absolute;
        width: 38px;
        height: 38px;
        border-radius: 100% !important;
        border: 2px solid #ebebeb;

        img {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:after {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          top: 9px;
          left: 12px;
          transform: rotate(45deg);
          transition: 0.2s ease-out all;
          border-right: 4px solid #102b35;
          border-bottom: 4px solid #102b35;
        }

        // Up
        &:nth-child(2) {
          z-index: 1;
          top: 30px;
          left: 50%;
          transform: translateX(-50%);

          &:after {
            top: 13px;
            border-top: 4px solid #102b35;
            border-left: 4px solid #102b35;
            border-bottom: 0;
            border-right: 0;
          }
        }

        // Down
        &:nth-child(3) {
          top: 74px;
          left: 50%;
          transform: translateX(-50%);
        }

        // Left
        &:nth-child(1) {
          top: 52px;
          left: 8px;

          &:after {
            top: 12px;
            left: 14px;
            border-top: 0;
            border-left: 4px solid #102b35;
            border-right: 0;
            border-bottom: 4px solid #102b35;
          }
        }

        // Right
        &:nth-child(4) {
          top: 52px;
          right: 8px;

          &:after {
            top: 12px;
            left: 10px;
            border-top: 4px solid #102b35;
            border-right: 4px solid #102b35;
            border-bottom: 0;
          }
        }

        &:hover {
          &:after {
            border-color: white;
          }
        }
      }
    }

    button label,
    .group label {
      transition: 0.2s all ease-out;
      color: #102b35;
      font-family: niveau-grotesk, sans-serif !important;
      font-weight: 600;
      font-weight: 600;
      font-size: 14px;
    }

    button {
      height: 44px;
      width: 130px;
      background: #000e15;
      position: relative;
      display: block;
      outline: 0 !important;
      box-shadow: none;
      cursor: pointer;
      position: relative;
      background: white;
      border: 2px solid #ebebeb;
      border-bottom: 2px solid #d1d1d1;

      &:last-child {
        border-bottom: 2px solid #ebebeb;
      }

      color: #102b35;
      transition: 0.2s all ease-out;

      .plan-toolbar-keyboard-shortcut {
        position: absolute;
        right: 10px;
        font-size: 12px;
        font-weight: 400;
        color: #b4b4b4;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: 1024px) {
          display: none;
        }
      }

      label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 34px;
        cursor: pointer;
      }

      // &:not(:first-child) {
      //   border-top-color: transparent;
      // }

      &:focus {
        outline: 0 !important;
      }

      &:hover {
        z-index: 1;
        background: #625afa;
        color: white;
        border-color: darken(#625afa, 10%);
        text-decoration: none;

        label {
          color: white;
        }

        svg {
          fill: white;
        }
      }

      &.active {
        background: #fff;
        border-color: darken(#625afa, 0%);
        text-decoration: none;

        .plan-toolbar-keyboard-shortcut {
          color: #625afa;
        }

        label {
          color: #625afa;
        }

        svg {
          fill: #625afa;
        }
      }

      &:first-child {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
      }

      svg,
      img {
        cursor: pointer !important;
        position: absolute;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        width: 20px;
        height: 20px;
        fill: #102b35;
      }
    }

    button,
    .group {
      &:last-child {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
  }

  .plan-toolbar.responsive {
    button {
      @media (max-width: 1500px) {
        height: 40px;
        width: 110px;

        label {
          left: 32px;
          font-size: 12px;
          line-height: 12px;
        }

        svg,
        img {
          height: 18px;
          width: 18px;
          left: 8px;
        }
      }

      @media (max-width: 1024px) {
        height: 44px;
        width: 44px;

        label {
          display: none;
        }

        svg,
        img {
          height: 22px;
          width: 22px;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      @media (max-width: 500px) {
        height: 38px;
        width: 38px;

        svg,
        img {
          height: 20px;
          width: 20px;
        }
      }
    }

    @media (max-width: 500px) {
      right: 5px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    }
  }
}
