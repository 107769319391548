﻿.floating {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  transition: opacity 0.2s ease-out;
  padding: 0;

  .toolbar {
    border-top: 2px solid #ebebeb;
    margin: 0;
    padding: 10px 5px;
    display: flex;
    justify-content: center;
    background: white;

    .status {
      display: block;
    }

    .buttons {
      margin: 0;
      display: flex;

      label {
        margin: 0;
        padding: 10px 15px;

        @media (max-width: 425px) {
          display: none;
        }

        @media (min-width: 600px) {
          font-size: 16px;
        }
      }

      button {
        margin: 0 5px;

        @media (min-width: 600px) {
          padding: 8px 24px;
          padding-left: 38px;
          font-size: 16px;

          svg {
            width: 26px;
          }
        }
      }
    }
  }
}

.seaty-messenger.padding .popup {
  bottom: 150px;
}

.seaty-messenger.padding .toggle {
  bottom: 103px;
}

.general-admission {
  position: relative;
  background: white;
}

.awards {
  .award {
    display: inline-block;
    position: relative;

    img {
      transition: all ease-in 0.2s;
      padding: 0;
      width: 32px;
      height: 32px;
      margin-top: 6px;
      margin-right: 6px;
      margin-bottom: 10px;
      opacity: 0.6;
    }

    .award-tooltip {
      border-radius: 6px;
      background: white;
      border: 1px solid lightgray;
      display: none;
      position: absolute;
      width: 300px;
      left: 50%;
      transform: translateX(-50%);
      padding: 10px 15px;
      text-align: center;
      color: #9e9e9e;
      font-size: 16px;
      line-height: 22px;
      z-index: 1;

      .date {
        font-family: 'Montserrat', sans-serif;
        color: black;
      }

      .name {
        font-weight: 300;
      }
    }

    &:hover {
      img {
        opacity: 1;
      }

      .award-tooltip {
        display: block;
      }
    }
  }
}

svg.diagonal {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  left: 0;
  right: 0;
  z-index: 9;

  &.top {
    bottom: auto;
    top: 0;
    -moz-transform: scale(-1);
    -o-transform: scale(-1);
    -webkit-transform: scale(-1);
    transform: scale(-1);
  }
}

.strip-block {
  @include page-title;
  border: 0;
  padding: 40px 80px;
  position: relative;
  @include niveau;
  text-transform: none;
  color: #000e15;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  text-align: left;

  @media (max-width: 1000px) {
    padding: 40px 50px;
  }

  input {
    display: block;
    margin: 13px 0;
    width: 100%;
    background: #fff;
    color: #000e15;
    transition: 0.3s ease-out;
    padding: 16px 16px;
    padding-left: 45px;
    letter-spacing: 0;
    line-height: 22px;
    font-size: 16px;
    height: auto !important;
    max-height: 100%;
    border: 2px solid #f5f5f5;
    border-radius: 6px;
    box-shadow: none;
    max-width: 450px;

    &:focus {
      outline: none;
    }
  }

  .subtitle {
    color: #5f6d90;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 2px;
  }

  .content {
    background-color: #f5f5f5;
    max-width: 1045px;
    margin: 0 auto;
  }

  .text {
    @include niveau;
    font-weight: 300;
    color: #435860;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 22px;

    a {
      font-weight: 400;
    }
  }

  .left {
    text-align: right;

    .text {
      max-width: 700px;
      white-space: pre-line;
    }
  }

  .right {
    text-align: left;

    .text {
      padding-right: 15px;
      max-width: 700px;
      white-space: pre-line;
    }
  }

  img {
    width: 100%;
    max-width: 300px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .event-badge {
    color: #9e9e9e;
    text-transform: none;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    display: inline-block;
    margin-left: 15px;

    img {
      width: 50px !important;
      padding: 0 !important;
      margin-right: 10px;
    }
  }

  @media (max-width: 768px) {
    padding: 45px 35px;

    img {
      padding: 25px;
    }

    .left {
      text-align: center;
    }

    .right {
      text-align: center;
    }
  }

  @media (max-width: 568px) {
    padding: 45px 30px;
  }

  @media (max-width: 468px) {
    padding: 45px 25px;
  }
}

p.error {
  background-color: $colour-angry;
  padding: 10px;
  margin: 0;
  margin-top: 10px;
  border-radius: 6px;
  color: white;
}

p.info {
  background-color: #323189;
  padding: 10px;
  margin: 0;
  margin-top: 10px;
  border-radius: 6px;
  color: white;
}

.modal-dialog {
  max-width: 400px;
}

@media (max-width: 768px) {
  .modal-dialog {
    margin: 10px auto;
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  .title {
    @include title-base;
  }

  textarea.notes {
    height: 64px !important;
  }

  .summary {
    .col {
      padding: 0;
    }
  }
}

$color-itembackground: #e1e1e1;

.btn-list {
  .bootstrap-select {
    margin: 0;
    display: block;

    .selectpicker {
      margin: 0;
    }

    .btn {
      outline: 0 !important;
      float: none;

      &:focus {
        outline: 0 !important;
      }
    }

    &.open {
      .btn {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .dropdown-menu {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    min-height: 0 !important;

    li a span.text {
      @include button-base;
    }

    &.inner {
      min-height: 0 !important;
    }
  }
}

.items-list {
  margin-top: 20px;

  .item-list-item {
    margin: 0;
    margin-bottom: 10px;
    padding: 10px;
    padding-top: 8px;
    border-radius: 4px;
    background-color: $color-itembackground;
    cursor: pointer;

    .bootstrap-select {
      margin: 0;
      display: block;

      .selectpicker {
        margin: 0;
      }

      .btn {
        outline: 0 !important;
        float: none;

        &:focus {
          outline: 0 !important;
        }
      }

      &.open {
        .btn {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        .dropdown-menu {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }

    .dropdown-menu {
      margin: 0;
      padding: 0;
      min-height: 0 !important;

      li a span.text {
        @include button-base;
      }

      &.inner {
        min-height: 0 !important;
      }
    }

    .bootstrap-select {
      width: auto !important;
    }

    .seatname {
      @include title-base;
      text-align: left;
      width: 100%;
      display: block;
      margin-top: 5px;
      margin-bottom: 10px;
    }

    option {
      color: white;
    }

    .remove-button {
      margin-top: -5px;
      float: right;
      width: auto;
      padding: 0;
      font-size: 24px;

      a {
        color: darken($color-itembackground, 30%);
      }

      a:hover,
      a:focus {
        color: darken($color-itembackground, 15%);
      }
    }
  }

  @media (min-width: 768px) {
    .item-list-item {
      padding: 6px;

      .bootstrap-select {
        margin: 0;
        display: inline-block;
        width: 64% !important;
        margin-top: -6px;
      }

      .seatname {
        @include title-base;
        text-align: left;
        width: 24%;
        padding-right: 0;
        margin-right: 0;
        display: inline-block;
        margin-top: 5px;
        margin-bottom: 0;
      }

      .remove-button {
        margin-top: 4px;
        margin-right: 6px;
        float: right;
        width: auto;
        padding: 0;
        font-size: 24px;

        a {
          color: darken($color-itembackground, 30%);
        }

        a:hover,
        a:focus {
          color: darken($color-itembackground, 15%);
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 500px;
  }
}

.dropdown-menu.inner.selectpicker {
  li a {
    -ms-opacity: 1;
    opacity: 1;
    font-size: 14px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  li a:hover {
    -ms-opacity: 0.8;
    opacity: 0.8;
  }
}
